import { Level } from '@nsalaun/ng-logger';

import { ApplicationNamesEnum, EnvironmentModel, StagesEnum } from '@svg/environment';

export const ENVIRONMENT: EnvironmentModel = {
	applicationName: ApplicationNamesEnum.myASI,
	apiInfix: 'api.myasi.de',
	clientId: 'myasi',
	enabledUpdateServiceWorker: true,
	logLevel: Level.OFF,
	name: StagesEnum.PRODUCTION,
	scope: 'openid',
	sentry: {
		url: 'https://651b1988e89d441393db16b9f0b23f43@o1347961.ingest.sentry.io/6631214',
		tracesSampleRate: 0.5,
	},
	tokenIssuer: 'https://svg-auth.de/realms/myasi',
};
